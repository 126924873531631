<template>
  <div style="height: 2000px; background-color: rgb(241, 241, 241)">
    <router-view class="flex-1 mb-10 min-h-screen p-2" />
  </div>
</template>

<script>
export default {
  created() {
    //   if (
    //     window.location.protocol !== "https:" &&
    //     process.env.NODE_ENV === "production"
    //   )
    //     window.location =
    //       "https://" +
    //       window.location.hostname +
    //       window.location.pathname +
    //       window.location.search;
  },
};
</script>